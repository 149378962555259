import React, { useEffect, useState } from 'react'
import './MiddleNavStyle.css'
import { Link } from 'react-router-dom'
import axios from 'axios'
import BaseURL from '../../apiconfig'
// import {  toast } from 'react-toastify';
// import { useSelector } from 'react-redux'


const MiddleNavbar = () => {
  const userVerify =localStorage.getItem('car-relation-user-token')


  const [nautificationData, setNautificationData]  = useState()
  const [enableNautification, setEnableNautification]  = useState(false)


  useEffect(()=>{
    if(nautificationData){
      if((nautificationData.finance_notifications&&nautificationData.finance_notifications.length>0) || (nautificationData.rto_notifications&&nautificationData.rto_notifications.length>0) || (nautificationData.insurance_notifications&&nautificationData.insurance_notifications.length>0)|| (nautificationData.advance_notifications&&nautificationData.advance_notifications.length>0)){
        setEnableNautification(true)
      }
    }
  },[nautificationData,enableNautification])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BaseURL}/get-all-notifications`, {
          mode: 'no-cors',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${userVerify}`,
          },
        });

        if (response.status >= 200 && response.status < 300) {
          const data = response.data;
          if (data) {
            // console.log(data.data);
            setNautificationData(data.data) 
          }
        } else {
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // toast.error(error.message);
      }
    };

    fetchData();
  }, [userVerify]);
  return (
    <div className='Middle-nav-main-div'>
        <div className='Middle-nav-sub-div'>
        <div className={userVerify?'Middle-nav-main-div-notification-icon':'Middle-nav-main-div-notification-icon-deactive'}>
          <div className={enableNautification?'Notification-Green-dot':'Notification-Green-dot-not-visible'}></div>
      <Link style={{color:'gray'}} to={'/NotificationPage'}><i className="fa-regular fa-bell"></i></Link>
      </div>
            <div className='Middle-nav-left-div'>
              {userVerify?<div className='user-logged-in-bars'>
              <Link  to={'/UserDashboard'}><i style={{color:'#eb7a9a', fontSize:'1.3rem'}} className="fa-solid fa-bars "></i></Link>
              </div>:
              <div className='mobile-user-login-div'>
              <Link style={{color:'#eb7a9a'}} to={'/UserMainPanel'}><i style={{color:'#eb7a9a'}} className="fa-solid fa-user use-panel-login-icon"></i></Link>
              <p style={{color:'#eb7a9a'}}>Login</p>
              </div>
              }
                {/* <img src="https://purepng.com/public/uploads/large/purepng.com-ford-focus-yellow-carcarvehicletransportford-961524665802mhbcd.png" alt="" /> */}
                <img src={process.env.PUBLIC_URL + '/NEW-LOGO2.png'} alt="" />
                <h2><i className="fa-solid fa-phone-volume"></i>9300007780</h2>
            </div>
            {/* <div className='Middle-nav-right-div'>
                <Link to={'/'}>Home</Link>
                <Link to={'/'}>About</Link>
                <Link to={'/'}>Contact</Link>
               
            </div> */}
        </div>
    </div>
  )
}

export default MiddleNavbar