import React, { useEffect, useState } from 'react'
import './NotificationPageStyle.css'
import { Link } from 'react-router-dom'
import BaseURL from '../../apiconfig'
import {  toast } from 'react-toastify';
import axios from 'axios';

const NotificationPage = () => {
  const userToken = localStorage.getItem('car-relation-user-token');
  const whichRole =localStorage.getItem('car-relation-user-get-role')

  const [financeOpen, setFinanceOpen] = useState(false);
  const [rTOOpen, setRTOOpen] = useState(false);
  const [insuranceOpen, setInsuranceOpen] = useState(false);
  const [financeDetailsOpen, setFinanceDetailsOpen] = useState([]);
  const [rTODetailsOpen, setRTODetailsOpen] = useState([]);
  const [insuranceDetailsOpen, setInsuranceDetailsOpen] = useState([]);
  const [allNotifications, setAllNotifications] = useState();

  const openFinance = () => {
    setFinanceOpen(!financeOpen);
    setInsuranceOpen(false);
    setRTOOpen(false);
  };

  const openRTO = () => {
    setRTOOpen(!rTOOpen);
    setFinanceOpen(false);
    setInsuranceOpen(false);
  };

  const openInsurance = () => {
    setInsuranceOpen(!insuranceOpen);
    setRTOOpen(false);
    setFinanceOpen(false);
  };

  const handleDetailsOpen = (index, type) => {
    switch (type) {
      case 'finance':
        setFinanceDetailsOpen(prevDetails =>
          prevDetails.map((state, i) => (i === index ? !state : state))
        );
        break;
      case 'rto':
        setRTODetailsOpen(prevDetails =>
          prevDetails.map((state, i) => (i===index ? !state : state))
        );
        break;
      case 'insurance':
        setInsuranceDetailsOpen(prevDetails =>
          prevDetails.map((state, i) => (i === index ? !state : state))
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BaseURL}/get-all-notifications`, {
          mode: 'no-cors',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        });

        if (response.status >= 200 && response.status < 300) {
          const data = response.data;
          if (data) {
            // console.log(data.data);
            setAllNotifications(data.data);

            // Initialize details open state for each type of notification
            setFinanceDetailsOpen(Array(data.data.finance_notifications&&data.data.finance_notifications.length).fill(false));
            setRTODetailsOpen(Array(data.data.rto_notifications&&data.data.rto_notifications.length).fill(false));
            if(whichRole==="Employee"){
              setInsuranceDetailsOpen(Array(data.data.advance_notifications&&data.data.advance_notifications.length).fill(false));
            }
            else{
              setInsuranceDetailsOpen(Array(data.data.insurance_notifications&&data.data.insurance_notifications.length).fill(false));
            }
          }
        } else {
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error(error.message);
      }
    };

    fetchData();
  }, [userToken,whichRole]);

  return (
    <div className="User-Affliation-sub-div">
      <Link to="/UserDashboard">
        <i className="fa-solid fa-arrow-left back-to-user-dashboard"></i>
      </Link>
      <div className="Affliation-Top-Heading">
        <h1>Notifications</h1>
      </div>
      <div className="Affliation-top-head-line"></div>
      <div  className="User-Adds-List-add-content-sub">
        <div className="Notification-tab-div">
          <div
            onClick={openFinance}
            className={financeOpen ? 'User-Adds-List-add-showing-div Notifications-heading-divs-active' : 'User-Adds-List-add-showing-div Notifications-heading-divs'}
          >
            <h3>Finance</h3>
          </div>
          <div
            onClick={openRTO}
            className={rTOOpen ? 'User-Adds-List-add-showing-div Notifications-heading-divs-active' : 'User-Adds-List-add-showing-div Notifications-heading-divs'}
          >
            <h3>RTO</h3>
          </div>
          <div
            onClick={openInsurance}
            className={insuranceOpen ? 'User-Adds-List-add-showing-div Notifications-heading-divs-active' : 'User-Adds-List-add-showing-div Notifications-heading-divs'}
          >
            
            <h3>{whichRole==='Employee'?'Advance':'Insurance'}</h3>
          </div>
        </div>

        <div
          style={{ padding: '30px 10px', height: 'auto', flexDirection: 'column', border: 'none' }}
          className={financeOpen ? 'User-Adds-List-add-showing-div' : 'Notifications-content-Close'}
        >
          {allNotifications &&allNotifications.finance_notifications&&
              allNotifications.finance_notifications.map((items, index) => (
                <div className="Finance-notifivcation-show-div" key={items.id}>
                  <div className="Finance-notifivcation-show-div-inner">
                    <h5>Vehicle No. : {items.vehicle_no}</h5>
                    <i
                      onClick={() => handleDetailsOpen(index, 'finance')}
                      className={financeDetailsOpen[index] ? 'fa-solid fa-angle-up' : 'fa-solid fa-angle-down'}
                    ></i>
                  </div>
  
                  <div className={financeDetailsOpen[index] ? 'Notification-Details-div' : 'Notification-Details-div-no-display'}>
                    <p>Date : {items.date}</p>
                    <p>Down Payment : {items.down_payment}</p>
                    <p>Finance Bank : {items.finance_bank}</p>
                    <p>Branch : {items.finance_bank_branch}</p>
                    <p>Customer Address : {items.owner_address}</p>
                    <p>Customer Name : {items.owner_name}</p>
                    <p>Customer Phone : {items.owner_phone}</p>
                    <p>Pending Amount : {items.pending_amount}</p>
                    <p>Finance Amount : {items.total_finance_amount}</p>
                  </div>
                </div>
              ))
            }
        </div>

        <div
          style={{ padding: '30px 10px', height: 'auto', flexDirection: 'column', border: 'none' }}
          className={rTOOpen ? 'User-Adds-List-add-showing-div' : 'Notifications-content-Close'}
        >
          {allNotifications &&allNotifications.rto_notifications&&
            allNotifications.rto_notifications.map((items, index) => (
              <div className="Finance-notifivcation-show-div" key={items.id}>
                <div className="Finance-notifivcation-show-div-inner">
                  <h5>Vehicle : {items.vehicle_no}</h5>
                  <i
                    onClick={() => handleDetailsOpen(index, 'rto')}
                    className={rTODetailsOpen[index] ? 'fa-solid fa-angle-up' : 'fa-solid fa-angle-down'}
                  ></i>
                </div>

                <div className={rTODetailsOpen[index] ? 'Notification-Details-div' : 'Notification-Details-div-no-display'}>
                  <p>Make : {items.make}</p>
                  <p>Model : {items.model}</p>
                  <p>Mfg year : {items.mfg_year}</p>
                  <p>Description : {items.description}</p>
                  <p>Owner Sr : {items.owners}</p>
                  <p>Km Driven : {items.km_driven}</p>
                  <p>Sale Value : {items.sale_value}</p>
                  <p>Sold Status : {items.is_sold?"Sold":"Not Sold"}</p>
                  <p>RC status : {items.is_rc_issued?"Yes":"No"}</p>
                </div>
              </div>
            ))}
        </div>

        <div
           style={{ padding: '30px 10px', height: 'auto', flexDirection: 'column', border: 'none' }}
          className={insuranceOpen ? 'User-Adds-List-add-showing-div' : 'Notifications-content-Close'}
        >
          {
            whichRole==='Employee'?
            (allNotifications &&allNotifications.advance_notifications&&
              allNotifications.advance_notifications.map((items, index) => (
                <div className="Finance-notifivcation-show-div" key={items.id}>
                  <div className="Finance-notifivcation-show-div-inner">
                    <h5>Amount : {items.loan_amount}</h5>
                    <i
                      onClick={() => handleDetailsOpen(index, 'insurance')}
                      className={insuranceDetailsOpen[index] ? 'fa-solid fa-angle-up' : 'fa-solid fa-angle-down'}
                    ></i>
                  </div>
  
                  <div className={insuranceDetailsOpen[index] ? 'Notification-Details-div' : 'Notification-Details-div-no-display'}>
                    <p>Date : {items.loan_date}</p>
                    <p>Loan Amount : {items.loan_amount}</p>
                    <p>Pending Amount : {items.balance_amount}</p>
                  </div>
                </div>
              )))
              :
          (allNotifications &&allNotifications.insurance_notifications&&
            allNotifications.insurance_notifications.map((items, index) => (
              <div className="Finance-notifivcation-show-div" key={items.id}>
                <div className="Finance-notifivcation-show-div-inner">
                  <h5>Vehicle No: {items.vehicle_no}</h5>
                  <i
                    onClick={() => handleDetailsOpen(index, 'insurance')}
                    className={insuranceDetailsOpen[index] ? 'fa-solid fa-angle-up' : 'fa-solid fa-angle-down'}
                  ></i>
                </div>

                <div className={insuranceDetailsOpen[index] ? 'Notification-Details-div' : 'Notification-Details-div-no-display'}>
                  <p>Amount : {items.amount}</p>
                  <p>From Date : {items.from_date}</p>
                  <p>Upto Date : {items.to_date}</p>
                  <p>Insurance Company : {items.insurance_company}</p>
                  <p>Insurance Company Branch : {items.insurance_company_branch}</p>
                  <p>Type : {items.type}</p>
                  <p>Customer Name : {items.owner_name}</p>

                </div>
              </div>
            )))}
        </div>
      </div>
    </div>
  );
};

export default NotificationPage;
