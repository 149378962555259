const BaseURL = 'https://manage.carrelation.net/api'

export default BaseURL;





// toast.success('You App is Under Process, Will be Uploaded after verification', {
//     position: 'top-center',
//     autoClose: 3000,
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//   });