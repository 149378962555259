const PendingDatas = [

    {
        id:1,
        src:'https://demoapus1.com/voiture/wp-content/uploads/2021/12/listing22-621x396.jpg',
        title:'2018 Hyundai Grand i10 master ',
        model:'1.2 Kappa Sportz BSIV',
        cost:'2,75,000',
        kilometres:'25,000'

    },
    {
        id:2,
        src:'https://demoapus1.com/voiture/wp-content/uploads/2021/12/listing15-621x396.jpg',
        title:'2018 Hyundai Grand i10 maste ',
        model:'1.2 Kappa Sportz BSIV',
        cost:'2,75,000',
        kilometres:'25,000'

    },
    {
        id:3,
        src:'https://demoapus1.com/voiture/wp-content/uploads/2021/12/listing12-621x396.jpg',
        title:'2018 Hyundai Grand i10 maste',
        model:'1.2 Kappa Sportz BSIV',
        cost:'2,75,000',
        kilometres:'25,000'

    },
    {
        id:4,
        src:'https://demoapus1.com/voiture/wp-content/uploads/2021/12/listing19-621x396.jpg',
        title:'2018 Hyundai Grand i10 maste',
        model:'1.2 Kappa Sportz BSIV',
        cost:'2,75,000',
        kilometres:'25,000'

    },
    {
        id:5,
        src:'https://demoapus1.com/voiture/wp-content/uploads/2021/12/listing13-621x396.jpg',
        title:'2018 Hyundai Grand i10 maste',
        model:'1.2 Kappa Sportz BSIV',
        cost:'2,75,000',
        kilometres:'25,000'

    },
    {
        id:6,
        src:'https://demoapus1.com/voiture/wp-content/uploads/2021/12/listing1-621x396.jpg',
        title:'2018 Hyundai Grand i10 maste',
        model:'1.2 Kappa Sportz BSIV',
        cost:'2,75,000',
        kilometres:'25,000'

    },
    {
        id:7,
        src:'https://demoapus1.com/voiture/wp-content/uploads/2021/12/listing18-621x396.jpg',
        title:'2018 Hyundai Grand i10 maste',
        model:'1.2 Kappa Sportz BSIV',
        cost:'2,75,000',
        kilometres:'25,000'

    },
    {
        id:8,
        src:'https://demoapus1.com/voiture/wp-content/uploads/2021/12/listing11-621x396.jpg',
        title:'2018 Hyundai Grand i10 maste',
        model:'1.2 Kappa Sportz BSIV',
        cost:'2,75,000',
        kilometres:'25,000'

    },
    {
        id:9,
        src:'https://demoapus1.com/voiture/wp-content/uploads/2021/12/listing14-621x396.jpg',
        title:'2018 Hyundai Grand i10 maste',
        model:'1.2 Kappa Sportz BSIV',
        cost:'2,75,000',
        kilometres:'25,000'

    },
]

module.exports= PendingDatas